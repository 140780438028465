<template>
  <div
    :style="styleObject"
    class="page-header align-items-start min-vh-screen my-transacions"
  >
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isFirstLoadDone">
          <div class="card-body pb-90">
            <div>
              <div
                class="color-common pb-3 font-weight-bolder h4"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                ATTENDANCE HISTORY
              </div>
              <div class="row rounded-3 border shadow p-3 bg-body mt-3">
                <div class="col-lg-12 col-12 mx-auto font-level-member">
                  <div class="row">
                    <div
                      class="col-lg-8 col-8 mx-auto d-flex justify-content-left align-items-center"
                    >
                      Home match attended: <span class="ms-2 price-bold label-color">{{ merchantMember.total_visit }}</span>
                    </div>
                    <div
                      class="col-lg-4 col-4 mx-auto d-flex justify-content-right align-items-center"
                    >
                      <span>{{ merchantMember.current_tier_name ?? "" }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-12 d-flex align-items-center">
                  <div class="col-lg-11 col-10 mx-auto">
                    <material-progress
                      :percentage="merchantMember.level_percentage"
                      variant="fill"
                      color="info"
                      class=""
                      style="height: 6px"
                    />
                  </div>
                  <div
                    class="col-lg-1 col-2 mx-auto"
                    v-if="merchantMember.tier_icon"
                  >
                    <img
                      :src="merchantMember.tier_icon"
                      class="avatar avatar-md border-radius-lg"
                      alt="user1"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-12 mx-auto font-level-member">
                  {{ merchantMember.level_note }}
                </div>
              </div>
              <div class="p-2"></div>
              <div class="d-flex justify-content-end">
                <Datepicker
                  v-model="timeSelect"
                  monthPicker
                  class="date-time"
                  :format="format"
                />
              </div>
              <div
                v-for="(transaction, index) in listTransaction"
                :item="transaction"
                :index="index"
                :key="transaction.index"
                class="row"
                v-bind:class="`${index.replace(', ', '_')}`"
              >
                <div class="mx-auto ps-0 pe-0">
                  <div
                    class="accordion"
                    :id="`accordionRental_${index.replace(', ', '_')}`"
                  >
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed p-0"
                          type="button"
                        >
                          <p class="pt-3 group-month text-muted">{{ index }}</p>
                        </button>
                      </h5>
                      <div v-if="transaction.length">
                        <div
                          v-for="(value, index2) in transaction"
                          :item="value"
                          :index="index2"
                          :key="index + value.index2"
                        >
                          <div class="text-sm opacity-8 mb-2">
                            <div
                              class="row p-1 bg-body transaction-item custom-shadow"
                            >
                              <div class="col-12 mx-auto">
                                <div
                                  class="fs-6 fst-normal brand-name text-sm text-bold"
                                  style="color: #000"
                                >
                                  {{ value.transaction_label ?? "" }}
                                </div>
                                <div>
                                  <em class="text-size-12">{{
                                    value.createdAt
                                  }}</em>
                                </div>
                              </div>
                              <div v-if="value.receipt_no">
                                No: {{ value.receipt_no }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="text-center">No item available</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FilterToolbarTransaction
          :filterArrCashbackEarnStatus="filterArrCashbackEarnStatus"
          :filterCashbackTimeSelected="filterCashbackTimeSelected"
          :filterArrStore="filterArrStore"
          v-if="typeSelected == 1"
        />
      </div>
    </div>
    <FilterTransaction
      :filterCashbackType="filterCashbackType"
      :filterArrCashbackEarnStatus="filterArrCashbackEarnStatus"
      :filterStore="filterStore"
      :filterArrStore="filterArrStore"
      :filterCashbackTime="filterCashbackTime"
      :filterData="filterData"
      :resetData="resetData"
    />
    <material-loading :active="loaderActive" />
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MemberService from "../services/member.service";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialProgress from "@/components/MaterialProgress.vue";
import FilterTransaction from "@/components/FilterTransaction.vue";
import FilterToolbarTransaction from "@/components/FilterToolbarTransaction.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import $ from "jquery";

export default {
  name: "my-admissions",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialProgress,
    FilterTransaction,
    FilterToolbarTransaction,
    Datepicker,
  },
  data() {
    return {
      isFirstLoadDone: false,
      listTransaction: [],
      merchantCode: "",
      loaderActive: false,
      typeSelected: 1,
      filterArrCashbackEarnStatus: [],
      filterArrStore: [],
      filterCashbackTimeSelected: null,
      timeSelect: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      },
      params: {
        filter_cashback_earn_status: [],
        filter_store: [],
        filter_cashback_time: null,
      },
      merchantMember: {},
    };
  },
  async mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
    await this.getMyTransactions();
    this.isFirstLoadDone = true;
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

    async getMyTransactions(type, paramsFilter = {}) {
      this.listTransaction = [];
      this.showLoader();

      await MemberService.getAdmissionsTransactions(
        type,
        this.timeSelect,
        paramsFilter
      ).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.listTransaction = response.data.data.list_transaction;
            this.merchantMember = response.data.data.merchant_member;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    filterCashbackType(idStatus) {
      this.filterArrCashbackEarnStatus = idStatus;
    },

    filterCashbackTime(value) {
      this.filterCashbackTimeSelected = value;
    },

    filterStore(value) {
      const index = this.filterArrStore.indexOf(value);
      if (index > -1) {
        this.filterArrStore.splice(index, 1);
      } else {
        this.filterArrStore.push(value);
      }
    },

    filterData() {
      $(".btn-close").click();
      $(".x-close").click();
      this.params.filter_cashback_earn_status = this.filterArrCashbackEarnStatus;
      this.params.filter_store = this.filterArrStore;
      this.params.filter_cashback_time = this.filterCashbackTimeSelected;
      this.getMyTransactions(1, this.params);
    },
    resetData() {
      $(".btn-unclick1").css({ "background-color": "#E3EBF7", color: "#000" });
      $(".btn-unclick2").css({ "background-color": "#E3EBF7", color: "#000" });
      $(".btn-unclick3").css({ "background-color": "#E3EBF7", color: "#000" });
      this.filterArrCashbackEarnStatus = [];
      this.filterArrStore = [];
      this.filterCashbackTimeSelected = null;
      this.filterData();
    },

    format(date) {
      const getMonth = String(date.getMonth() + 1);
      const month = getMonth.padStart(2, "0");
      const year = String(date.getFullYear());
      return `${month}-${year}`;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    timeSelect(val) {
      if (val) {
        if (this.typeSelected == 1) {
          this.getMyTransactions(this.typeSelected, this.params);
        } else {
          this.getMyTransactions(this.typeSelected);
        }
      }
    },
  },
};
</script>

<style></style>
