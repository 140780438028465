<template>
  <div class="page-header align-items-start" :style="styleObject">
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body ps-0 pe-0 pb-90">
            <div
              class="pb-0 font-weight-bolder h4"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              Donation
            </div>
            <div class="d-flex justify-content-end">
              <div @click="gotoTransaction()">
                <img
                  class="icon-history"
                  src="../assets/img/method/history.png"
                />
                <span class="ps-2 link-style text-size-14">History</span>
              </div>
            </div>
            <div class="mt-3">
              <div class="input-group input-group-static">
                <label
                  for="form-control"
                  class="ms-0"
                  :style="`color: black !important`"
                  >Enter Amount</label
                >
                <div class="form-control p-0 mt-3" id="form-control">
                  <div class="d-flex">
                    <i class="material-icons-round opacity-10 cursor-pointer"
                      @click="donateAmount ? upDownAmount() : null"
                      >remove</i
                    >
                    <material-input
                      :extraClass="'text-center'"
                      id="donateAmount"
                      name="donateAmount"
                      v-model="donateAmount"
                      :type="'number'"
                      @input="validateDonateAmount"
                      @keydown.prevent.up="preventUpDown"
                      @keydown.prevent.down="preventUpDown"
                    />
                    <i class="material-icons-round opacity-10 cursor-pointer"
                      @click="upDownAmount(1)"
                      >add</i
                    >
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <div class="color-common font-weight-bolder" :style="`color: ${this.$store.state.labelColor} !important`">
                  Terms & Conditions
                </div>
                <div v-html="terms_conditions" class="font-content text-dark c-terms-conditions"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-donate mx-auto">
      <button
        :disabled="!donateAmount || donateAmount <= 0"
        :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
        type="button"
        class="btn bg-color-common btn-success"
        @click="confirmDonate"
      >
        Proceed to Payment
      </button>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
    <ModalConfirmDonate
      :amount="donateAmount"
      btnYes="Yes"
      :sycn-profile="processPayment"
    />
    <DonateCompleted :amount="currentAmount" />
  </div>
</template>

<script>
import MaterialLoading from "@/components/MaterialLoading.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import StripeService from "../services/stripe.service";
import HomeService from "../services/home.service";
import MaterialInput from "@/components/MaterialInput.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import ModalConfirmDonate from "@/components/ModalConfirmDonate.vue";
import DonateCompleted from "@/components/DonateCompleted.vue";
import $ from "jquery";
export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialInput,
    DonateCompleted,
    ModalConfirmDonate
  },
  data() {
    return {
      prices: [],
      merchantCode: "",
      donateAmount: null,
      currentAmount: 0,
      loaderActive: false,
      snackbar: "",
      message: "",
      terms_conditions: ""
    };
  },
  created() {
    this.getDonationTermsConditions();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    const isCheckMerchantConnectedStripe = localStorage.getItem(
      "IS_CHECK_MERCHANT_CONNECTED_STRIPE",
      "false"
    );
    this.merchantCode = code ? `/${code}` : "";

    if (isCheckMerchantConnectedStripe === "false") {
      window.location.href = this.merchantCode + "/home";
    }
    //load after payment done
    var session_id = this.$route.query.session_id;
    var amount = this.$route.query.price;

    if (session_id) {
      // call back after pay success - stripe
      this.showLoader();
      var dataForm = {
        session_id: session_id,
        amount: amount
      }

      StripeService.saveDonate(dataForm, this.merchantCode).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.currentAmount = amount;
            $(".btn-donate_completed_modal").click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    }
  },
  computed: {
    styleObject() {
      return {
        "--button-color": this.$store.state.buttonColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    validateDonateAmount(event) {
      var amount = parseFloat(event.target.value);

      if (amount < 0) {
        this.donateAmount = 0;
      }
    },
    preventUpDown(event) {
      // Check if the up or down arrow key was pressed
      if (event.keyCode === 38 || event.keyCode === 40) {
        // Prevent the default behavior of the key event
        event.preventDefault();
      }
    },
    upDownAmount(type = 0) {
      if (type) {
        this.donateAmount += 1;
      } else {
        if (this.donateAmount > 1) {
          this.donateAmount -= 1;
        } else {
          this.donateAmount = 0;
        }
      }
    },
    confirmDonate() {
      $('.btn-confirm-modal-donate').click();
    },
    selectMethod(method) {
      this.currentMethod = method;
    },
    getDonationTermsConditions() {
      this.showLoader();
      HomeService.getDonationTermsConditions().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.terms_conditions = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      )
    },
    processPayment() {
      this.showLoader();
      const merchantCode = localStorage.getItem("MERCHANT_CODE", "");
      var url =
        location.protocol +
        "//" +
        location.host +
        "/" +
        merchantCode +
        "/donation";
      var payment_amount = new Intl.NumberFormat("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(this.donateAmount);
      var dataSend = {
        success_url:
          url +
          "?session_id={CHECKOUT_SESSION_ID}&price=" +
          payment_amount,
        cancel_url: url,
        images: "",
        name: "Donate S$" + payment_amount,
        unit_amount: payment_amount,
        quantity: 1,
      };

      StripeService.checkoutSessionStripe(dataSend, merchantCode).then(
        (response) => {
          if (response.data.result.isSuccess) {
            window.location.href = response.data.data.url;
          } else {
            this.hideLoader();
          }
        },
        (error) => {
          console.log(error);
          this.hideLoader();
        }
      );
    },
    gotoTransaction() {
      this.$router.push(this.merchantCode + "/my-transactions");
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header {
  height: calc(100vh - 56px);
}
.link-style {
  color: blue;
}
.icon-history {
  width: 20px;
  height: 20px;
}
#form-control i {
  width: 35px;
  height: 32px;
  border-radius: 3px;
  text-align: center;
  background: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
  padding: 4px 2px;
}
.footer-donate {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  left: 0px;
}
</style>
