<template>
  <div class="page-header align-items-start">
    <span class="mask bg-white"></span>
    <div class="container" v-if="isShowLayout">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 pt-2 custom-icon-back py-2">
          <a @click="landing()"><i class="fas fa-chevron-left color-common" :style="`color: ${this.$store.state.labelColor} !important`"></i></a>
        </div>
        <div class="col-lg-12 col-md-8 col-12 mx-auto vh-90 pb-90 content-scroll">
          <div class="mb-3" style="position: relative;">
            <div class="row">
              <div class="voucher-item">
                  <div class="d-flex justify-content-between">
                    <div class="col-4">
                      <img
                        :src="`${rewardDetail.icon_url}`"
                        class="voucher-image"
                        alt="user1"
                      />
                    </div>
                    <div class="col-8 ps-0">
                      <div class="d-flex align-items-center">
                        <strong
                          class="text-size-13 ps-0 w-70"
                          :style="`color: ${this.$store.state.labelColor} !important`"
                          >{{ rewardDetail.reward_title ?? "" }}</strong
                        >
                        <div class="amount-exchange w-30 pe-1" v-if="merchantMember.reward_type == 1">
                          {{ rewardDetail.amount_text }}
                        </div>
                        <div class="amount-exchange w-30 pe-1" v-if="merchantMember.reward_type == 2">
                          {{ rewardDetail.point_text }}
                        </div>
                      </div>
                      <div class="d-flex">
                        <em class="text-size-13">Valid for: {{ rewardDetail.expiry_date_text }}</em>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="row mb-2" v-if="rewardDetail.tier_can_exchange_text">
            <div class="d-flex bd-highlight">
              <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
              <p class="font-content text-dark">{{ rewardDetail.tier_can_exchange_text }}</p>
            </div>
          </div>
          <div class="row mb-2" v-if="rewardDetail.branch_can_exchange_text">
            <div class="d-flex bd-highlight">
              <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
              <p class="font-content text-dark">{{ rewardDetail.branch_can_exchange_text }}</p>
            </div>
          </div>
          <div class="row mb-2" v-if="rewardDetail.item_apply_text">
            <div class="d-flex bd-highlight">
              <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
              <p class="font-content text-dark">{{ rewardDetail.item_apply_text }}</p>
            </div>
          </div>
          <div class="row mb-2" v-if="rewardDetail.order_minimum_text">
            <div class="d-flex bd-highlight">
              <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
              <p class="font-content text-dark">{{ rewardDetail.order_minimum_text }}</p>
            </div>
          </div>
          <hr class="row" v-if="rewardDetail.tier_can_exchange_text || rewardDetail.branch_can_exchange_text || rewardDetail.item_apply_text || rewardDetail.order_minimum_text">
          <div class="row mb-2" v-if="rewardDetail.terms_arr.length">
            <div class="color-common font-weight-bolder ps-0" :style="`color: ${this.$store.state.labelColor} !important`">
              Terms & Conditions
            </div>
          </div>
          <div v-for="(value, index) in rewardDetail.terms_arr" :item="value" :index="index" :key="index"
            class="row mb-2">
            <div class="d-flex bd-highlight" v-if="value">
              <i class="fas fa-check pe-2 pt-1"></i>
              <p class="font-content text-dark c-terms-conditions">{{ value }}</p>
            </div>
          </div>

          <div class="row mb-2" v-if="rewardDetail.where_to_use != '' && rewardDetail.where_to_use != null">
            <div class="color-common font-weight-bolder ps-0" :style="`color: ${this.$store.state.labelColor} !important`">
              Where to use:
            </div>
          </div>
          <div class="row mb-2" v-if="rewardDetail.where_to_use != '' && rewardDetail.where_to_use != null">
            <div class="d-flex bd-highlight" v-if="rewardDetail.where_to_use">
              <i class="fas fa-check pe-2 pt-1"></i>
              <p class="font-content text-dark where-to-use" v-html="rewardDetail.where_to_use"></p>
            </div>
          </div>
          <div class="text-center btn-flex" v-if="!rewardDetail.is_disabled_btn"><button class="btn mb-3 btn-success btn-md col-lg-4 col-md-5 col-6 bg-color-common"
              :disabled="rewardDetail.is_disabled_btn"
              :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`" data-bs-toggle="modal"
              data-bs-target="#bottom_modal">Exchange</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <Exchange
      :rewardPerTransaction="merchantMember.reward_type ?? 0"
      :voucherData="rewardDetail"
      :amount_format="merchantMember.amount_format" 
      :amount_in_account="merchantMember.amount_in_account" 
      :point_format="rewardDetail.point_text"
      :point_in_account="merchantMember.point"
      :is_disabled="rewardDetail.is_disabled"
      :is_disabled_by_card="rewardDetail.is_disabled_by_card"
      :confirm-exchange="confirmExchange"
    />

    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date="" :description="message"
        :icon="{ component: 'campaign', color: 'white' }" :color="snackbar" :close-handler="closeSnackbar" />
    </div>
    <material-loading :active="loaderActive" />
    <ModalConfirm
      message="Do you want to exchange this voucher?"
      btnYes="Yes"
      :sycn-profile="processExchange"
    />
    <ModalSuccess :processAcction="gotoMyReward"
      message="Voucher has been exchanged successfully and saved to My Reward" buttonText="Go to My Reward" />
  </div>

</template>

<script>
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import RewardService from "../services/reward.service";
import 'bootstrap/dist/js/bootstrap.bundle'
import Exchange from "@/components/Exchange.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalSuccess from "@/components/ModalSuccess.vue";
import $ from "jquery";
import HomeService from "../services/home.service";
import ModalConfirm from "@/components/ModalConfirm.vue";

export default {
  name: "reward-detail",
  components: {
    MaterialSnackbar,
    Exchange,
    MaterialLoading,
    ModalSuccess,
    ModalConfirm
  },
  data() {
    return {
      rewardDetail: {},
      merchantMember: {},
      loaderActive: false,
      snackbar: null,
      isShowLayout: false,
      merchantCode: ""
    };
  },
  created() {
    this.getRewardDetail();

    var session_id = this.$route.query.session_id;
      var reward_id = this.$route.query.reward_id;
      if(session_id && reward_id) { // call back after pay success - stripe
        this.showLoader();
        this.isLoadDone = false;
        HomeService.processExchangeByCard(reward_id, session_id).then(
          (response) => {
            if (response.data.result.isSuccess) {
              // const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
              // var url = location.protocol + '//' + location.host+"/"+merchantCode+"/home";
              // window.location.href = url;
              this.getRewardDetail();
              this.snackbar = "success";
              this.message = "Exchange Successfully!";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
          }
        );
      }
  },
  mounted() {
    const code = localStorage.getItem('MERCHANT_CODE', '');
    this.merchantCode = code ? `/${code}` : ''
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

    getRewardDetail() {
      // this.closeSnackbar()
      this.showLoader()
      var rewardId = this.$route.params.reward_id
      RewardService.getRewardDetail(rewardId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.rewardDetail = response.data.data.reward
            this.merchantMember = response.data.data.merchant_member
            this.isShowLayout = true
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },

    processExchange() {
      $(".btn-close-confirm-modal").click();
      this.closeSnackbar()
      this.showLoader()
      const rewardId = this.rewardDetail.id ?? 0
      RewardService.processExchange(rewardId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close").click()
            this.getRewardDetail()
            $('.btn-success-modal').click()
          } else {
            $(".btn-close").click()
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },

    confirmExchange() {
      $(".btn-confirm-modal").click();
    },

    gotoMyReward() {
      $(".btn-close").click()
      this.$router.push(this.merchantCode + '/my-rewards');
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => { this.snackbar = null; }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    async landing() {
      $(".btn-close").click()
      this.$router.go(-1)
      // this.$router.push(this.merchantCode + (checkMemberPermissions ? '/reward-catalogue' : '/home'));
    }
  },
};
</script>
<style scope>
.where-to-use a {
  color: #007BFF !important;
  text-decoration: underline !important;
}

.transaction-detail {
  font-weight: 500;
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.font-custom {
  font-size: 14px;
}

.custom-icon-back {
  font-size: 25px;
}


.text-custom {
  color: black !important;
  font-weight: 500;
}

.group-month {
  font-weight: 400;
}

.group-month {
  margin-right: 10px;
}

.brand-name {
  cursor: pointer
}

.font-level-member {
  font-size: 12px;
}

.font-content {
  font-size: 13px;
  font-weight: 600;
}

.font-sign {
  font-size: 22px;
}

.title-voucher {
  font-size: 14px;
}

.font-size-custom {
  font-size: 30px;
  top: 25px;
}
.voucher-item {
  padding: 5px;
  background: hsla(0, 0%, 100%, 0.5);
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}
.amount-exchange {
  color: #000000;
  font-weight: bold;
  text-align: right;
  min-width: 100px !important;
}
.btn-flex {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
}
.vh-90 {
  min-height: 90vh;
}
@media only screen and (max-width: 1024px) {
    .font-size-custom {
      font-size: 16px;
      top: 6px;
      right: 10px;
    }
}
@media only screen and (max-width: 600px) {
    .font-size-custom {
      font-size: 16px;
      top: 6px;
      right: 10px;
    }
}
</style>