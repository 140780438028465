<template>
  <nav class="shadow-none navbar navbar-main navbar-expand-lg p-2 ps-0 pe-0" v-click-outside="clickOutSide"
    v-bind="$attrs" id="navbarBlur" v-if="checkShowHideNavbar" data-scroll="true" :class="isAbsolute ? 'mt-4' : 'mt-0'">
    <div class="px-0 py-1 container-fluid">
      <!-- <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar">
        <ul class="navbar-nav justify-content-start">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body lh-1" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-center">
        <img src="../../assets/img/team-2.jpg" class="avatar avatar-md mb-3 border-radius-lg" alt="user1" />
        <h2 class="py-2 ps-2">Lava</h2>
      </div>
      <div>
        <img src="../../assets/img/team-2.jpg" class="avatar avatar-md mb-3 border-radius-lg" alt="user1" />
      </div> -->
      <div class="row col-12 py-1">
        <div class="col-4">
          <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'"
            id="navbar">
            <ul class="navbar-nav justify-content-start">
              <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body lh-1" id="iconNavbarSidenav">
                  <div class="sidenav-toggler-inner mt-1">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-4">
          <div class="d-flex justify-content-center mt-1">
            <img v-if="this.$store.state.merchantLogo" :src="this.$store.state.merchantLogo" class="avatar-header avatar-md border-radius-lg" alt="user1" />
            <img v-else src="../../assets/img/logo.png" class="avatar-header avatar-md border-radius-lg" alt="user1" />
          </div>
        </div>
        <div class="col-4 pe-0">
          <div class="d-flex justify-content-end">
            <img  src="../../assets/img/qrcode.png" class="avatar-qr avatar-md border-radius-lg" alt="user1" data-bs-toggle="modal" data-bs-target="#qrcode-modal-member"/>
          </div>
        </div>
      </div>
      <!-- <breadcrumbs :currentPage="currentRouteName" :color="color" /> -->
      <QrcodeMember :phoneNumber="phone_number" />
    </div>
  </nav>
</template>
<script>
// import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";
import QrcodeMember from "@/components/QrcodeMember.vue";
import TokenService from "../../services/token.service";
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      phoneNumber: '',
      memberInfo: {}
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav;
    var memberLogin = TokenService.getMember();
    this.memberInfo = memberLogin?.data.user;
    this.phone_number = memberLogin?.data.user.phone_number ?? 'Phone number not found!'
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
    clickOutSide: function () {
      if (window.innerWidth <= 1200) {
        if (!this.isPinned) {
          this.toggleSidebar()
        }
      }

    }
  },
  components: {
    // Breadcrumbs,
    QrcodeMember,
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute", "isPinned"]),

    currentRouteName() {
      return this.$route.name;
    },

    checkShowHideNavbar() {
      if (this.$route.name === 'TransactionDetails' || this.$route.name === 'RewardDetail' || this.$route.name === 'MyRewardDetail') {
        return false;
      } else {
        return true;
      }
    }
  },
};
</script>
