<template>
  <div
    class="modal modal-bottom fade"
    id="bottom_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="bottom_modal"
    style="z-index: 10000"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border: 0; padding: 0.1rem 1rem">
          <!-- <h5 class="modal-title">Bottom Modal Title</h5> -->
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="rewardPerTransaction && rewardPerTransaction == 1">
            <input
              type="radio"
              id="payment-exchange"
              name="payment-exchange"
              value="One"
              v-model="picked"
              :disabled="!voucherData.is_disabled ? disabled : ''"
            />
            <label for="one">Use Wallet</label>
          </div>
          <div
            v-else-if="rewardPerTransaction && rewardPerTransaction == 2 && (!voucherData.is_bundle || voucherData.points_convert > 0)"
          >
            <input
              type="radio"
              id="payment-exchange"
              name="payment-exchange"
              value="One"
              v-model="picked"
              :disabled="!voucherData.is_disabled ? disabled : ''"
            />
            <label for="one">Use {{ voucherData.point_name }}</label>
          </div>
          <div v-if="voucherData.is_bundle">
            <input
              type="radio"
              id="payment-exchange"
              name="payment-exchange"
              value="Two"
              v-model="picked"
              :disabled="!voucherData.is_disabled_by_card ? disabled : ''"
            />
            <label for="one">Pay by Card</label>
          </div>
          <!-- <input type="radio" id="two" value="Two" v-model="picked" :disabled="!voucherData.is_disabled_by_card ? disabled : ''" />
          <label for="two">Pay by Card</label> -->
          <hr />
          <div v-if="picked === 'One' && !voucherData.is_disabled">
            <div class="row d-flex bd-highlight">
              <div class="row">
                <div class="col-4">
                  <img
                    :src="`${voucherData.icon_url}`"
                    class="voucher-image"
                    alt="user1"
                  />
                </div>
                <div class="col-8 ps-0">
                  <div class="d-flex">
                    <span
                      class="text-size-13 ps-0 w-100 text-bold"
                      :style="`color: ${this.$store.state.labelColor} !important`"
                      >{{ voucherData.reward_title ?? "" }}</span
                    >
                  </div>
                  <em class="text-size-12"
                    >Valid for: {{ voucherData.expiry_date_text }}</em
                  >
                </div>
              </div>
            </div>
            <div v-if="rewardPerTransaction != 0">
              <div class="d-flex">
                <div
                  class="col-lg-5 col-5 align-self-center"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  Total {{ rewardPerTransaction == 1 ? "Wallet" : voucherData.point_name }}
                </div>
                <div
                  class="col-lg-7 col-7 d-flex bd-highlight d-flex justify-content-end"
                >
                  <img
                    v-if="rewardPerTransaction == 2"
                    :src="voucherData.point_icon"
                    class="avatar avatar-md border-radius-lg"
                    alt="user1"
                    style="height: 45px;"
                  />
                  <img
                    v-else
                    src="../assets/img/coins.png"
                    class="avatar avatar-md border-radius-lg"
                    alt="user1"
                  />
                  <h4 class="ps-2 pt-2">
                    {{
                      rewardPerTransaction == 1 ? amount_format : point_format
                    }}
                  </h4>
                </div>
              </div>
              <div class="row d-flex bd-highlight text-custom font-custom">
                <div class="col-lg-5 col-5 align-self-center">
                  Payment {{ rewardPerTransaction == 1 ? "Amount" : voucherData.point_name }}
                </div>
                <div
                  class="col-lg-7 col-7 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
                >
                  {{
                    rewardPerTransaction == 1
                      ? voucherData.amount_text
                      : voucherData.point_text
                  }}
                </div>
              </div>
              <hr />
              <div class="row d-flex bd-highlight text-custom font-custom">
                <div class="col-lg-5 col-5 align-self-center">Balance</div>
                <div
                  v-if="rewardPerTransaction == 1"
                  class="col-lg-7 col-7 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
                  :class="{'text-danger': parseFloat(voucherData.amount_convert) > parseFloat(amount_in_account)}"
                >
                  S${{
                    new Intl.NumberFormat("en", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(
                      parseFloat(amount_in_account) -
                        parseFloat(voucherData.amount_convert)
                    )
                  }}
                </div>
                <div
                  v-else
                  class="col-lg-7 col-7 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
                  :class="{'text-danger': voucherData.points_convert > point_in_account}"
                >
                  {{ formatPoint(parseInt(point_in_account) - parseInt(voucherData.points_convert)) }}
                </div>
              </div>
            </div>
            <div v-else>
              <hr />
            </div>
          </div>
          <div v-else-if="picked === 'Two' && !voucherData.is_disabled_by_card">
            <div
              class="color-common"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              Transaction Detail
            </div>
            <div class="row d-flex bd-highlight text-custom font-custom">
              <div class="col-lg-6 col-6 align-self-center">Item</div>
              <div
                class="col-lg-6 col-6 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
              >
                {{ voucherData.reward_title ?? "" }}
              </div>
            </div>
            <div class="row d-flex bd-highlight text-custom font-custom">
              <div class="col-lg-6 col-6 align-self-center">Item Price</div>
              <div
                class="col-lg-6 col-6 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
              >
                {{ voucherData.amount_text }}
              </div>
            </div>
            <div class="row d-flex bd-highlight text-custom font-custom">
              <div class="col-lg-6 col-6 align-self-center">Tax</div>
              <div
                class="col-lg-6 col-6 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
              >
                S$ 0
              </div>
            </div>
            <div class="row d-flex bd-highlight text-custom font-custom">
              <div class="col-lg-6 col-6 align-self-center">
                Transaction fee
              </div>
              <div
                class="col-lg-6 col-6 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
              >
                S$ 0
              </div>
            </div>
            <div class="row d-flex bd-highlight text-custom font-custom">
              <h6 class="col-lg-6 col-6">Payment Amount</h6>
              <div
                class="col-lg-6 col-6 d-flex bd-highlight d-flex justify-content-end text-custom font-custom"
              >
                {{ voucherData.amount_text }}
              </div>
            </div>

            <br />
            <div
              class="row d-flex bd-highlight text-custom font-custom"
              v-if="snackbar === 'danger' || snackbar === 'success'"
            >
              <div
                class="col-lg-12 col-12 align-self-center txt-color-error-stripe"
              >
                {{ message }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="text-center"
          v-if="!voucherData.is_disabled_by_card || !is_disabled"
        >
          <material-button
            class="btn mb-3 col-lg-2 col-md-5 col-4 bg-color-common"
            :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
            @click="handleSelectPay"
          >
            Proceed
          </material-button>
        </div>
      </div>
    </div>
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import StripeService from "../services/stripe.service.js";
import MaterialLoading from "@/components/MaterialLoading.vue";
import $ from "jquery";

export default {
  name: "Exchange",
  components: {
    MaterialLoading
  },
  props: {
    keyReload: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    voucherData: {
      type: Object,
      required: true,
    },
    rewardPerTransaction: {
      type: Number,
      default: 0,
    },
    amount_format: {
      type: String,
      required: true,
    },
    amount_in_account: {
      type: String,
      default: "",
    },
    point_format: {
      type: String,
      required: true,
    },
    point_in_account: {
      type: Number,
      default: 0,
    },
    confirmExchange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isPayByCard: true,
      picked: "One",
      message: "",
      snackbar: null,
      loaderActive: false,
    };
  },
  beforeUpdate() {
    // if (this.voucherData.is_disabled_by_card == true) {
    //   this.picked = "One";
    // }
  },
  methods: {
    formatPoint(point) {
      return point + ' ' + this.voucherData.point_name;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    handleSelectPay() {
      $(".btn-close").click();

      if (this.picked === "One") {
        this.confirmExchange();
      } else {
        this.showLoader();
        var url = window.location.href;
        StripeService.checkoutSessionStripe({
          success_url:
            url +
            "?session_id={CHECKOUT_SESSION_ID}&reward_id=" +
            this.voucherData.id,
          cancel_url: url,
          images: this.voucherData.icon_url,
          name: this.voucherData.reward_title,
          unit_amount: this.voucherData.amount_convert,
          quantity: 1,
        }).then(
          (response) => {
            if (response.data.result.isSuccess) {
              window.location.href = response.data.data.url;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
          }
        );
      }
    },
  },
  watch: {
    keyReload(val) {
      if (val) {
        if (this.rewardPerTransaction && this.rewardPerTransaction == 2 && this.voucherData.points_convert == 0) {
          this.picked = 'Two';
        } else {
          this.picked = 'One';
        }
      }
    }
  },
};
</script>
<style scoped>
.bg-color-common {
  color: #fff;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}

.txt-color-error-stripe {
  color: red;
}
</style>
