
import MemberService from "../services/member.service";

const checkMemberPermissions = async (to, from, next) => {
  const { meta, name } = to;
  const isActive = meta && meta.active !== undefined ? meta.active : true;

  if (isActive) {
    try {
      let listRouteCheck = [
        'Top-up',
        'Donation',
        'Transactions',
        'My Rewards',
        'My Campaigns',
        'Reward Catelogue',
        'Invite Friends',
        'Find Branches',
        'Contact Us',
      ];

      if (listRouteCheck.includes(name)) {
        const response = await MemberService.checkMemberPermissions();
        let memberPermissions = response.data.data;

        if (memberPermissions.includes(name)) {
          const response2 = await MemberService.checkProfile();
          let profilePass = response2.data.data;

          if (profilePass) {
            next(true);
          } else {
            next('/minty/profile');
          }
        } else {
          next(false);
        }
      } else {
        const response2 = await MemberService.checkProfile();
        let profilePass = response2.data.data;

        if (profilePass) {
          next(true);
        } else {
          next('/minty/profile');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export default checkMemberPermissions;
