<template>
  <div
    class="modal modal-bottom fade"
    id="qrcode-modal-member"
    tabindex="-1"
    role="dialog"
    aria-labelledby="qrcode-modal-member"
    style="z-index: 10000"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border: 0; padding: 0.1rem 1rem">
          <!-- <h5 class="modal-title">Bottom Modal Title</h5> -->
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="isLogined">
          <div class="row">
            <div class="col-12 mx-auto mb-5">
              <div class="member-info">
                <div class="d-flex">
                  <div class="w-25">
                    <img
                      v-if="this.$store.state.merchantLogo" 
                      :src="this.$store.state.merchantLogo"
                      alt=""
                      style="width:80px !important; height: auto !important;"
                    />
                  </div>
                  <div class="w-75 ms-3 text-end tier-name">Tier: {{ memberInfo.tier_name }}</div>
                </div>
                <div class="mt-3" style="font-size: 14px">
                  <div>
                    Name:
                    {{
                      memberInfo.name || memberInfo.last_name
                        ? (memberInfo.name ? memberInfo.name : "") +
                          " " +
                          (memberInfo.last_name
                            ? memberInfo.last_name
                            : "")
                        : memberInfo.phone_number
                        ? memberInfo.phone_number
                        : ""
                    }}
                  </div>
                  <div class="text-end mt-3">Member ID: {{ memberInfo.member_id_text }}</div>
                </div>
              </div>
            </div>
            <div class="col-12 mx-auto mb-5">
              <div class="wrap-qrcode text-center">
                <VueQRCodeComponent
                  :text="phoneNumber"
                  size="200"
                  color="#222222"
                  bg-color="#ffffff"
                  error-level="L"
                  class="d-flex justify-content-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";
import moment from "moment";
import MemberService from '../services/member.service';
import EventBus from "vue3-eventbus";

export default {
  name: "QrcodeReward",
  components: {
    VueQRCodeComponent,
  },
  props: {
    phoneNumber: {
      type: String,
      default: "Phone number not found!",
    },
  },
  data() {
    return {
      isLogined: false,
      merchantLogo: localStorage.getItem('MERCHANT_LOGO', ''),
      memberInfo: null
    };
  },
  created() {
    this.getMemberInfo();
  },
  mounted() {
    EventBus.on("reloadMembershipCard", () => {
      this.getMemberInfo();
    });
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/Y");
    },
    getMemberInfo() {
      MemberService.getMemberInfo().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.memberInfo = response.data.data;
              this.isLogined = true;
            }
          },
          (error) => {
            console.log("error", error);
          }
      );
    },
  }
};
</script>
<style scoped>
.bg-color-common {
  color: #fff;
}

#qrcode-modal .modal-dialog {
  height: 70vh;
}

#qrcode-modal .modal-body {
  overflow-y: scroll;
  height: 68vh;
}

.wrap-qrcode {
  width: 200px;
  margin: 0px auto;
}

#qrcode-modal p {
  margin: 0px;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}

.wrap-code {
  border: 1px solid rgb(228, 228, 228);
  position: relative;
  width: 200px;
  margin: 0px auto;
  left: 12px;
}

.wrap-code i {
  position: absolute;
  right: 5px;
  top: 5px;
}

.member-info {
  background-image: url("~@/assets/img/bg-member-card.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 15px 25px;
  color: #222;
  border-radius: 15px;
}

.member-info .tier-name {
  font-size: 24px;
  font-weight: bold;
}
</style>
