<template>
  <div class="page-header align-items-start" :style="styleObject">
    <span class="mask bg-white"></span>
    <div class="container p-0" v-if="isLoadDone">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body ps-0 pe-0 pb-90">
            <div
              @click="backTermsConditions()"
              class="pb-0 font-weight-bolder h4 px-2"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              <i class="fas fa-chevron-left me-2"></i> Back
            </div>
            <div class="mt-3 px-2">
              <div class="mt-2 timeslot">
                <div class="text-title">Booking</div>
                <div class="d-flex align-items-center ms-5 mt-2">
                  <i class="material-icons-round opacity-10">calendar_month</i>
                  <div class="ms-3">
                    <div>{{ formatDate(timeSlotSelect.date) }}</div>
                    <div>{{ memberBooking.time_text }}</div>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <div class="text-title">Venue</div>
                <div class="d-flex align-items-center ms-5 mt-2">
                  <img
                    :src="memberBooking.venue_image"
                    class="venue-img"
                    alt="Error"
                  />
                  <div class="ms-3">{{ memberBooking.venue_name }}</div>
                </div>
              </div>
              <div class="mt-2" v-if="memberBooking.price > 0">
                <div class="text-title">Price</div>
                <div class="ms-5">
                  <div>Total: {{ memberBooking.total_price_format }}</div>
                  <!-- <div>Venue Cost: {{ memberBooking.price_format }}</div> -->
                  <div>{{ memberBooking.gst_rate }}% GST: {{ memberBooking.gst_format }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-timeslot mx-auto">
        <button
          @click="processBooking()"
          :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
          type="button"
          class="btn bg-color-common btn-success mx-auto"
        >
          Proceed to Payment
        </button>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import BookingService from "../services/booking.service";
import moment from "moment";

export default {
  name: "Booking completed",
  components: {
    MaterialSnackbar,
    MaterialLoading,
  },
  data() {
    return {
      isLoadDone: false,
      merchantCode: "",
      loaderActive: false,
      snackbar: "",
      message: "",
      memberBooking: {},
      venueId: this.$route.params.id ?? 0,
      branchId: this.$route.query.branch_id ?? 0,
    };
  },
  props: {
    typeSelect: {
      type: Object,
      required: true,
    },
    timeSlotSelect: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.timeSlotDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  computed: {
    styleObject() {
      return {
        "--button-color": this.$store.state.buttonColor,
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  methods: {
    backTermsConditions() {
      this.$emit("back-terms-conditions");
    },

    formatDate(date) {
      return moment(date).format("dddd, D MMMM YYYY");
    },

    timeSlotDetail() {
      this.isLoadDone = false;
      this.showLoader();

      var dataForm = {
        slot_id: this.timeSlotSelect.id,
        venue_id: this.venueId,
        venue_type: this.typeSelect.id,
        branch_id: this.branchId,
      };

      BookingService.timeSlotDetail(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.memberBooking = response.data.data;
            this.isLoadDone = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    processBooking() {
      this.showLoader();
      const merchantCode = localStorage.getItem("MERCHANT_CODE", "");
      var url =
        location.protocol +
        "//" +
        location.host +
        "/" +
        merchantCode +
        "/booking-completed/booking_code";
      var dataSend = {
        success_url: url + "?session_id={CHECKOUT_SESSION_ID}",
        cancel_url: url,
        images: "",
        name: "Booking venue",
        unit_amount: this.memberBooking.total_price,
        quantity: 1,
        venue_id: this.venueId,
        venue_type_id: this.typeSelect.id,
        slot_id: this.timeSlotSelect.id,
        date_select: this.timeSlotSelect.date,
        branch_id: this.branchId,
      };

      BookingService.checkoutSessionStripe(dataSend, merchantCode).then(
        (response) => {
          if (response.data.result.isSuccess) {
            window.location.href = response.data.data.url;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header {
  height: calc(100vh - 56px);
}

.footer-timeslot {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  left: 0px;
}

.timeslot i {
  font-size: 40px;
  color: #222;
}

.venue-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
}
</style>
