import api from './api';

class StripeService {
    checkoutSessionStripe(dataForm, merchant_code) {
        return api.post('/payment/checkout-session-stripe', dataForm, {
            headers: {
            'MerchantCode': merchant_code
            }
          }
        );
    }

    addDirectAmountAfterPayWithCard(session_id, amount_direct_increased, id, merchant_code) {
        return api.post('/member/add-direct-amount-after-pay-with-card', { session_id: session_id, amount_direct_increased: amount_direct_increased, topup_id: id }, {
            headers: {
            'MerchantCode': merchant_code
            }
          }
        );
    }

    saveDonate(dataForm, merchant_code) {
      return api.post('/member/save-donate', dataForm, {
          headers: {
          'MerchantCode': merchant_code
          }
        }
      );
  }
}

export default new StripeService();
