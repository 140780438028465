<template>
    <div :style="styleObject" class="page-header align-items-start min-vh-screen my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-12" style="text-align: center;">
            <!-- <i class="material-icons-round opacity-10 fs-5" :style="`font-size: 10rem !important;color: ${this.$store.state.labelColor}`">flag</i> -->
            <div
              class="color-common pb-2 pt-3 font-weight-bolder h4"
              :style="`color: green !important;text-align: center`"
            >
              Activate Premium Membership
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: center;" v-if="tier_have_membership_fee_min">
          <div style="width: 450px;background: lightgray;padding: 20px;">
            <p style="display: inline-flex;align-items: center;gap: 10px;"><i class="material-icons-round opacity-10" style="color: green">check_circle</i> Pay S${{ tier_have_membership_fee_min ? Number(tier_have_membership_fee_min.membership_fee_total).toFixed(2) : 0 }} and enjoy greater perks </p>
            <p style="display: inline-flex;align-items: center;gap: 10px;"><i class="material-icons-round opacity-10" style="color: green">check_circle</i> Earn & Spend cashback in any transaction </p>
            <p style="display: inline-flex;align-items: center;gap: 10px;"><i class="material-icons-round opacity-10" style="color: green">check_circle</i> Unlimited access to vouchers & rewards </p>

            <div class="row my-3" v-if="tier_have_membership_fee_min.description">
                <div class="color-common font-weight-bolder"
                    :style="`color: ${this.$store.state.labelColor} !important`">
                    Description
                </div>
            </div>
            <div class="row mx-2 text-size-14" v-if="tier_have_membership_fee_min.description">
                {{ tier_have_membership_fee_min.description }}
            </div>
            <div class="row my-3" v-if="tier_have_membership_fee_min.spending_achieve_tier_format || tier_have_membership_fee_min.duration_achieve_membership">
                <div class="color-common font-weight-bolder"
                    :style="`color: ${this.$store.state.labelColor} !important`">
                    Condition
                </div>
            </div>
            <div class="row mx-2 text-size-14">
                {{ tier_have_membership_fee_min.is_paid_member_ship ? "Paid Membership" : "Free Membership" }}
            </div>
            <div class="row mx-2 text-size-14" v-if="tier_have_membership_fee_min.is_paid_member_ship && tier_have_membership_fee_min.already_achieved_a_paid_tier_previously">
                Already achieved a paid tier previously
            </div>
            <div class="mx-2 text-size-14 d-flex align-items-center" v-if="tier_have_membership_fee_min.is_paid_member_ship">
                Membership Fee {{ tier_have_membership_fee_min.membership_fee_total_format }}
                <!-- <div class="btn-complted ms-2" v-if="tier_have_membership_fee_min.completed_payment_membership_fee">
                    Completed
                </div> -->
            </div>
            <div class="row mx-2 text-size-14" v-if="tier_have_membership_fee_min.spending_achieve_tier > 0 && tier_have_membership_fee_min.spending_achieve_tier_format">
                Spend {{ tier_have_membership_fee_min.spending_achieve_tier_format }}
            </div>
            <div class="row mx-2 text-size-14" v-if="tier_have_membership_fee_min.duration_achieve_membership">
                Remain loyalty for {{ tier_have_membership_fee_min.duration_achieve_membership }} {{ tier_have_membership_fee_min.duration_achieve_membership_unit_text ? tier_have_membership_fee_min.duration_achieve_membership_unit_text.label : '' }}
            </div>
            <div class="row my-3" v-if="tier_have_membership_fee_min.standard_rule_text || tier_have_membership_fee_min.superior_rule_text || tier_have_membership_fee_min.welcome_voucher_text || tier_have_membership_fee_min.birthday_voucher_text">
                <div class="color-common font-weight-bolder"
                    :style="`color: ${this.$store.state.labelColor} !important`">
                    Benefit
                </div>
            </div>
            <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.standard_rule_text">
                <i class="fas fa-check pe-2 pt-1"></i>{{ tier_have_membership_fee_min.standard_rule_text }}
            </div>
            <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.superior_rule_text">
                <i class="fas fa-check pe-2 pt-1"></i>{{ tier_have_membership_fee_min.superior_rule_text }}
            </div>
            <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.welcome_voucher_text" style="">
                <i class="fas fa-check pe-2 pt-1"></i>Welcome Benefit
                <p style="margin-bottom: 0px;" class="text-size-14" v-for="(line, index) in tier_have_membership_fee_min.welcome_voucher_text.split('---')" :key="index">{{ line ? " - "+line : '' }}</p>
            </div>
              <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.welcome_direct_cashback_text">
                <i class="fas fa-check pe-2 pt-1"></i>{{ tier_have_membership_fee_min.welcome_direct_cashback_text }}
            </div>
            <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.birthday_voucher_text" style="">
              <i class="fas fa-check pe-2 pt-1"></i>Birthday Benefit
              <p style="margin-bottom: 0px;" class="text-size-14" v-for="(line, index) in tier_have_membership_fee_min.birthday_voucher_text.split('---')" :key="index">{{ line ? " - "+line : '' }}</p>
            </div>
            <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.birthday_direct_cashback_text && nodeEnv === 'staging' && (tier_have_membership_fee_min.standard_rule_text || tier_have_membership_fee_min.superior_rule_text)">
                <i class="fas fa-check pe-2 pt-1"></i>{{ tier_have_membership_fee_min.birthday_direct_cashback_text }}
            </div>
            <div class="mx-2 text-size-14" v-if="tier_have_membership_fee_min.birthday_extra_rebase_text && nodeEnv === 'staging' && (tier_have_membership_fee_min.standard_rule_text || tier_have_membership_fee_min.superior_rule_text)">
                <i class="fas fa-check pe-2 pt-1"></i>{{ tier_have_membership_fee_min.birthday_extra_rebase_text }}
            </div>
          </div>
        </div>
        <div class="row" style="justify-content: center;margin-top: 10px;" v-if="tier_have_membership_fee_min">
          <div style="align-self: center;width: fit-content;">
            <input
              type="radio"
              id="payment-exchange"
              name="payment-exchange"
              value="One"
              v-model="pickedPay"
              :disabled="Number(memberInfo.getTotalTopupAmountCanSpent) < (tier_have_membership_fee_min ? Number(tier_have_membership_fee_min.membership_fee_total) : 0)"
            />
            <label for="one">Use Wallet Top Up - $S{{ Number(memberInfo.getTotalTopupAmountCanSpent).toFixed(2) }}</label>
          </div>
          <div style="align-self: center;width: fit-content;">
            <input
              type="radio"
              id="payment-exchange"
              name="payment-exchange"
              value="Two"
              v-model="pickedPay"
            />
            <label for="two">Use Credit Card</label>
          </div>
        </div>
        <div class="row" style="justify-content: center;margin-top: 10px;" v-if="tier_have_membership_fee_min">
          
        </div>
        <div class="row" style="justify-content: center;margin-top: 10px;" v-if="tier_have_membership_fee_min">
          <material-button
            @click="processPayment"
            class="mb-1 col-lg-2 col-md-3 col-4 no-bg btn-sm btn-exchange"
            :style="`color: rgb(34, 34, 34); border: 1px solid rgb(186, 180, 174) !important;`"
          >
            <div class="text-center">Proceed</div>
          </material-button>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
    </div>
  </template>
  
  <script>
  import MaterialAlert from "@/components/MaterialAlert.vue";
  import { mapMutations, mapActions } from "vuex";
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import MemberService from "../services/member.service";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialButton from "@/components/MaterialButton.vue";
  import StripeService from "../services/stripe.service";
  import EventBus from "vue3-eventbus";
  import TierService from "../services/tier.service";
  
  export default {
    name: "my-transacions",
    components: {
      MaterialAlert,
      MaterialSnackbar,
      MaterialButton,
    },
    data() {
      return {
        inviteUrl: "",
        merchantCode: "",
        memberInfo: {},
        message: "Copied your code to clip board",
        snackbar: "",
        tier_have_membership_fee_min: null,
        pickedPay: "One",
        tiers: [],
      };
    },
    mounted() {
      const code = localStorage.getItem("MERCHANT_CODE", "");
      this.merchantCode = code ? `/${code}` : "";
      this.getTiers();
    },
    computed: {
      styleObject() {
          return {
              '--label-color': this.$store.state.labelColor,
          };
      },
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
      ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
      getMemberInfo() {
        MemberService.getProfile().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.memberInfo = response.data.data;

              if(this.tier_have_membership_fee_min && this.memberInfo.member_tier == this.tier_have_membership_fee_min.id) {
                window.location.href = this.merchantCode+'/home'
              } 

              //   const code = localStorage.getItem('MERCHANT_CODE', '');
              // var merchantCode = code ? `/${code}` : ''
              const isCheckMerchantConnectedStripe = localStorage.getItem("IS_CHECK_MERCHANT_CONNECTED_STRIPE", 'false');
              // var count_paid_membership_reward_by_merchant = this.memberInfo.count_paid_membership_reward_by_merchant;
              // if(!this.memberInfo.is_paid_member_ship && isCheckMerchantConnectedStripe === 'true' && count_paid_membership_reward_by_merchant > 0) {
              //     window.location.href = location.protocol + '//' + location.host+"/minty/profile"
              // } 
              if(isCheckMerchantConnectedStripe === 'false') {
                window.location.href = this.merchantCode+'/home'
              } 
              // else {
              //     // window.location.href = location.protocol + '//' + location.host+"/minty/profile"
              //     this.$router.push(this.merchantCode + '/get-member-ship-success');
              // }
              // if(!this.memberInfo.tier_have_membership_fee_min) {
              //   window.location.href = this.merchantCode+'/home'
              // }

              if(this.memberInfo.is_paid_member_ship) {
                  // window.location.href = location.protocol + '//' + location.host+"/minty/profile"
                  // this.$router.push(this.merchantCode + '/get-member-ship-success');
                  // window.location.href = this.merchantCode+'/home'
              }

              if(Number(this.memberInfo.getTotalTopupAmountCanSpent) > 0) {
                this.pickedPay = "One";
              } else {
                this.pickedPay = "Two";
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      copyURL() {
        const ipnElement = document.querySelector(".copytextarea");
        ipnElement.select(); // step 4
        document.execCommand("copy"); // step 5
      },
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
      processPayment() {
        if(this.pickedPay == "Two") {
          // click refdirect to srtipe payment
          this.loaderActive = true;
          const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
          var url = location.protocol + '//' + location.host+"/minty/profile";
          var payment_amount = new Intl.NumberFormat("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          }).format(this.tier_have_membership_fee_min.membership_fee_total);
          var dataSend = {
              success_url: url+"?session_id={CHECKOUT_SESSION_ID}&price=" + this.tier_have_membership_fee_min.membership_fee_total + "&tier_id="+this.tier_have_membership_fee_min.id,
              cancel_url: url,
              images: "",
              name: "PAID MEMBERSHIP " + payment_amount,
              unit_amount: this.tier_have_membership_fee_min.membership_fee_total,
              quantity: 1
          };
          StripeService.checkoutSessionStripe(dataSend, merchantCode).then(
              (response) => {
              if (response.data.result.isSuccess) {
                  window.location.href = response.data.data.url
              } else {
                  this.loaderActive = false;
              }
              },
              (error) => {
              console.log(error);
              this.loaderActive = false;
              }
          );
        } else {
          var price = this.tier_have_membership_fee_min.membership_fee_total;
          var tier_id = this.tier_have_membership_fee_min.id;
          if(price && tier_id) {
              var dataForm = {
                  amount: price,
                  tier_id: tier_id
              }
              MemberService.assignPaidMembershipRewardsToMemberUsingCashbackTopup(dataForm).then(
                  (response) => {
                      var dataResponse = response.data;
                      if (dataResponse.result.isSuccess) {
                          this.snackbar = 'success'
                          this.message = "Proceed successfully"
                          this.setTimeOutNotification()
                          EventBus.emit("reloadSideNav");
                          this.getMemberInfo();
                          this.$router.push(this.merchantCode + '/get-member-ship-success');
                      } else {
                          this.$router.push(this.merchantCode + '/get-member-ship');
                          this.snackbar = 'danger'
                          this.message = response.data.result.message
                          this.setTimeOutNotification();
                      }
                  },
                  (error) => {
                      console.log(error);
                  }
              );
          }
        }
      },
      getTiers() {
        var rewardId = this.$route.params.reward_id
        this.loaderActive = true;
        TierService.getListTier().then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.tiers = response.data.data
                    const exists = this.rewardExists(rewardId, response.data.data);
                    if (exists && exists.is_paid_member_ship == 1) {
                      this.tier_have_membership_fee_min = exists;
                      this.getMemberInfo();
                    } else {
                      this.$router.push(this.merchantCode + '/home');
                    }
                } else {
                    this.snackbar = 'danger'
                    this.message = response.data.result.message
                    this.setTimeOutNotification()
                }
                this.loaderActive = false;
            },
            (error) => {
                this.isShowLayout = false;
                this.snackbar = 'danger'
                this.message = error
                this.setTimeOutNotification()
                this.loaderActive = false;
            }
        );
      },
      rewardExists(rewardId, data) {
        return data.find(obj => obj.id == rewardId);
      }
    },
  };
  </script>
  <style>
  .item-content h4 {
    font-size: 16px;
    color: #8f8e8e;
    font-weight: 500;
  }
  
  .item-content em {
    font-size: 14px;
    color: #222;
  }
  
  .avatar-invite {
    width: 80px;
    height: 90px;
  }
  #invite-friends p {
    font-size: 13px;
  }
  </style>