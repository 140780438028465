<template>
    <div :style="styleObject" class="page-header align-items-start min-vh-screen my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isFirstLoadDone">
                    <div class="card-body pb-90">
                        <div>
                            <div class="color-common pb-3 font-weight-bolder h4"
                                :style="`color: ${this.$store.state.labelColor} !important`">
                                TRANSACTION HISTORY
                            </div>
                            <div>
                                <div class="row d-flex justify-content-center">
                                    <ul class="transaction-tab ps-0 pe-0" :style="styleObject" :class="{'show-point-transaction': merchantMember.reward_type == rewardPoint, 'show-donation-transaction': merchantMember.show_donation != 0}">
                                        <li class="nav-item active" id="nav_item_1" @click="changeType(1)"
                                            :style="`color: ${this.$store.state.labelColor} !important`">
                                            <strong>Wallet</strong>
                                        </li>
                                        <li v-if="merchantMember.reward_type == rewardPoint" class="nav-item" id="nav_item_11" @click="changeType(11)"
                                            :style="`color: ${this.$store.state.labelColor} !important`">
                                            <strong>{{ pointName }}</strong>
                                        </li>
                                        <li class="nav-item" id="nav_item_2" @click="changeType(2)"
                                            :style="`color: ${this.$store.state.labelColor} !important`">
                                            <strong>Receipt</strong>
                                        </li>
                                        <li v-if="merchantMember.show_donation != 0" class="nav-item" id="nav_item_3" @click="changeType(3)"
                                            :style="`color: ${this.$store.state.labelColor} !important`">
                                            <strong>Donation</strong>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row pt-4" v-if="[1, 11].includes(typeSelected) && merchantMember && merchantMember.allow_show_lifetime_earn_cashback != 0">
                                    Lifetime earning: {{ totalLifetimeEarning }}
                                </div>
                                <div class="row pt-4 cashback-detail" v-if="[1, 11].includes(typeSelected)">
                                    <div :class="`${ merchantMember && merchantMember.allow_show_redeemed_cashback != 0 ? 'col-lg-4 col-4' : 'col-lg-6 col-6' } d-flex justify-content-center text-status`" style="border-right: 2px solid #CBCBCB;">
                                        Available
                                    </div>
                                    <div :class="`${ merchantMember && merchantMember.allow_show_redeemed_cashback != 0 ? 'col-lg-4 col-4' : 'col-lg-6 col-6' } d-flex justify-content-center text-status`" :style="`${ merchantMember && merchantMember.allow_show_redeemed_cashback != 0 ? 'border-right: 2px solid #CBCBCB;' : '' }`">
                                        Pending
                                    </div>
                                    <div class="col-lg-4 col-4 d-flex justify-content-center text-status" v-if="merchantMember && merchantMember.allow_show_redeemed_cashback != 0">
                                        Redeemed
                                    </div>
                                </div>
                                <div class="row cashback-detail" v-if="[1, 11].includes(typeSelected)">
                                    <div :class="`${ merchantMember && merchantMember.allow_show_redeemed_cashback != 0 ? 'col-lg-4 col-4' : 'col-lg-6 col-6' } d-flex justify-content-center text-status`" style="border-right: 2px solid #CBCBCB;`">
                                        {{ typeSelected == 11 ? membershipPoint : membershipCashback }}
                                    </div>
                                    <div :class="`${ merchantMember && merchantMember.allow_show_redeemed_cashback != 0 ? 'col-lg-4 col-4' : 'col-lg-6 col-6' } d-flex justify-content-center text-status`" :style="`${ merchantMember && merchantMember.allow_show_redeemed_cashback != 0 ? 'border-right: 2px solid #CBCBCB;' : '' }`">
                                        {{ totalPendingEarnedAmount }}
                                    </div>
                                    <div class="col-lg-4 col-4 d-flex justify-content-center text-status" v-if="merchantMember && merchantMember.allow_show_redeemed_cashback != 0">
                                        {{ totalCashbackUserd }}
                                    </div>
                                </div>
                                <div class="row text-size-12 ps-2 pt-1" v-if="typeSelected == 1 && isLoadDone && merchantMember.notice_transaction_amount_expiry.length">
                                    <div v-for="(text, index) in merchantMember.notice_transaction_amount_expiry" :key="index">
                                        <em v-html="text"></em>
                                    </div>
                                </div>
                                <div class="row rounded-3 border shadow p-3 bg-body mt-4" v-if="merchantMember && merchantMember.allow_setup_membership_tiering != 0 && merchantMember.allow_show_membership_spending != 0 && typeSelected == 2">
                                    <div class="col-lg-12 col-12 mx-auto font-level-member">
                                        <div class="row">
                                            <div class="col-lg-5 col-5 mx-auto d-flex justify-content-left align-items-center">
                                                Total Spending
                                            </div>
                                            <div class="col-lg-7 col-7 mx-auto d-flex justify-content-right align-items-center">
                                                <span class="price-bold label-color">{{ merchantMember.amount_spent }}</span> <span>{{ merchantMember.current_tier_name ?? "" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 d-flex align-items-center">
                                        <div class="col-lg-11 col-10 mx-auto">
                                            <material-progress :percentage="merchantMember.level_percentage"
                                                variant="fill" color="info" class="" style="height: 6px;" />
                                        </div>
                                        <div class="col-lg-1 col-2 mx-auto" v-if="merchantMember.tier_icon">
                                            <img :src="merchantMember.tier_icon"
                                                class="avatar avatar-md border-radius-lg" alt="user1" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 mx-auto font-level-member">
                                        {{ merchantMember.level_note }}
                                    </div>
                                </div>
                                <div class="row rounded-3 border shadow p-3 bg-body mt-4" v-if="merchantMember && typeSelected == 3">
                                    <div class="col-lg-12 col-12 mx-auto font-level-member">
                                        <div class="row">
                                            <div class="col-lg-5 col-5 mx-auto d-flex justify-content-left align-items-center">
                                                Total Donation
                                            </div>
                                            <div class="col-lg-7 col-7 mx-auto d-flex justify-content-right align-items-center">
                                                <span class="price-bold label-color">{{ totalDonation }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 mx-auto font-level-member">
                                        Thank you so much for your contribution!
                                    </div>
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="d-flex justify-content-end">
                                <Datepicker
                                    v-model="timeSelect"
                                    monthPicker
                                    class="date-time"
                                    :format="format"
                                />
                            </div>
                            <div v-for="(transaction, index) in listTransaction" :item="transaction" :index="index"
                                :key="transaction.index" class="row" v-bind:class="`${index.replace(', ', '_')}`">
                                <div class="mx-auto ps-0 pe-0">
                                    <div class="accordion" :id="`accordionRental_${index.replace(', ', '_')}`">
                                        <div class="accordion-item ">
                                            <h5 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed p-0" type="button">
                                                    <p class="pt-3 group-month text-muted">{{ index }}</p>
                                                </button>
                                            </h5>
                                            <div v-if="transaction.length">
                                                <div v-for="(value, index2) in transaction" :item="value" :index="index2" :key="index + value.index2">
                                                    <div class="text-sm opacity-8 mb-2">
                                                        <div class="row p-1 bg-body transaction-item custom-shadow">
                                                            <div class="col-lg-8 col-8 mx-auto">
                                                                <div class="fs-6 fst-normal brand-name text-sm text-bold" style="color: #000">
                                                                    {{ value.transaction_label ?? ''}} </div>
                                                                <div><em class="text-size-12">{{ value.createdAt }}</em></div>
                                                            </div>
                                                            <div class="col-lg-4 col-4 mx-auto justify-content-end d-flex align-items-center ps-0 pe-0">
                                                                <div :class="`d-flex justify-content-center text-bold ${value.transaction_type_object.SIGN == '+' ? 'label-color' : 'default-color'}`">
                                                                    <div v-if="type != 3 && value.payment_amount != 0 || value.amount_direct_deduct != 0" :class="`${value.transaction_type_object.SIGN == '+' ? 'label-color' : 'default-color'}`">{{ value.transaction_type_object.SIGN
                                                                    }}</div>
                                                                    <div class="ps-1 fs-6 fst-normal pe-2" v-if="typeSelected == 1 && value.transaction_type == 2">
                                                                        {{ value.amount_direct_deduct_format }}
                                                                    </div>
                                                                    <div class="ps-1 fs-6 fst-normal pe-2" v-else>
                                                                        {{ value.payment_amount_format }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="value.receipt_no">Receipt No: {{ value.receipt_no }}
                                                            </div>
                                                            <div class="col-12 d-flex pt-3" v-if="typeSelected == 1 && (value.earned_expiry_date || value.earned_pending_time)">
                                                                <div class="col-lg-9 col-9 mx-auto" style="color: #222">
                                                                    {{ value.earned_status == 1 ? 'Valid until ' + value.earned_expiry_date : 'Available by ' + value.earned_pending_time }}
                                                                </div>
                                                                <div :class="`col-lg-3 col-3 mx-auto justify-content-end d-flex ${ value.earned_status == 1 ? 'text-confirmed' : 'text-pedding' } text-size-13`">
                                                                    {{ value.earned_status == 1 ? 'Confirmed' : 'Pending' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center">No item available</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FilterToolbarTransaction
                :filterArrCashbackEarnStatus="filterArrCashbackEarnStatus"
                :filterCashbackTimeSelected="filterCashbackTimeSelected"
                :filterArrStore="filterArrStore"
                v-if="typeSelected == 1"
            />
        </div>
        <FilterTransaction
            :filterCashbackType="filterCashbackType"
            :filterArrCashbackEarnStatus="filterArrCashbackEarnStatus"
            :filterStore="filterStore"
            :filterArrStore="filterArrStore"
            :filterCashbackTime="filterCashbackTime"
            :filterData="filterData"
            :resetData="resetData"
        />
        <material-loading :active="loaderActive" />
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
    </div>

</template>

<script>
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MemberService from "../services/member.service";
import 'bootstrap/dist/js/bootstrap.bundle'
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialProgress from "@/components/MaterialProgress.vue";
import FilterTransaction from "@/components/FilterTransaction.vue";
import FilterToolbarTransaction from "@/components/FilterToolbarTransaction.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import $ from "jquery";

export default {
    name: "my-transacions",
    components: {
        MaterialSnackbar,
        MaterialLoading,
        MaterialProgress,
        FilterTransaction,
        FilterToolbarTransaction,
        Datepicker
    },
    data() {
        return {
            listTransaction: [],
            totalLifetimeEarning: "",
            membershipCashback: "",
            membershipPoint: "",
            totalPendingEarnedAmount: "",
            totalCashbackUserd: "",
            totalDonation: 0,
            currentTime: '',
            merchantCode: "",
            loaderActive: false,
            typeSelected: 1,
            isLoadDone: false,
            isFirstLoadDone: false,
            filterArrCashbackEarnStatus: [],
            filterArrStore: [],
            filterCashbackTimeSelected: null,
            timeSelect: {
                year: (new Date()).getFullYear(),
                month: (new Date()).getMonth()
            },
            params: {
                filter_cashback_earn_status: [],
                filter_store: [],
                filter_cashback_time: null,
            },
            merchantMember: {},
            rewardPoint: 2,
            pointName: 'Point'
        };
    },
    async mounted() {
        const code = localStorage.getItem('MERCHANT_CODE', '');
        this.merchantCode = code ? `/${code}` : '';
        await this.getMyTransactions(this.typeSelected, this.params);
        this.isFirstLoadDone = true;
    },
    computed: {
        styleObject() {
            return {
                '--label-color': this.$store.state.labelColor,
            };
        }
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

        changeType(type) {
            this.typeSelected = type;
            this.timeSelect = {
                year: (new Date()).getFullYear(),
                month: (new Date()).getMonth()
            }
        },

        async getMyTransactions(type, paramsFilter = {}) {
            this.isLoadDone = false;
            this.listTransaction = []
            $('ul.transaction-tab >li').removeClass('active');
            $('#nav_item_' + type).addClass("active")
            this.showLoader();
            console.log(this.timeSelect);
            await MemberService.getMyTransactions(type, this.timeSelect, paramsFilter).then(
                (response) => {
                    console.log('response', response)
                    this.hideLoader();
                    this.listTransaction = response.data.data.list_transaction
                    this.totalLifetimeEarning = response.data.data.total_lifetime_earning ? response.data.data.total_lifetime_earning : "S$ 0"
                    this.membershipCashback = response.data.data.membership_cashback ? response.data.data.membership_cashback : "S$ 0"
                    this.membershipPoint = response.data.data.membership_point ? response.data.data.membership_point : "S$ 0"
                    this.totalPendingEarnedAmount = response.data.data.total_pending_earned_amount ? response.data.data.total_pending_earned_amount : "S$ 0"
                    this.totalCashbackUserd = response.data.data.total_cashback_userd ? response.data.data.total_cashback_userd : "S$ 0"
                    this.totalDonation = response.data.data.total_donation ? response.data.data.total_donation : "S$ 0"
                    this.currentTime = response.data.data.current_year_month
                    this.merchantMember = response.data.data.merchant_member
                    this.pointName = response.data.data.point_name
                    this.isLoadDone = true;
                },
                (error) => {
                    this.hideLoader();
                    console.log(error);
                }
            );
        },

        filterCashbackType(idStatus) {
            this.filterArrCashbackEarnStatus = idStatus
        },

        filterCashbackTime(value) {
            this.filterCashbackTimeSelected = value
        },

        filterStore(value) {
            const index = this.filterArrStore.indexOf(value);
            if (index > -1) {
                this.filterArrStore.splice(index, 1);
            } else {
                this.filterArrStore.push(value);
            }
        },

        filterData() {
            $(".btn-close").click();
            $(".x-close").click();
            this.params.filter_cashback_earn_status = this.filterArrCashbackEarnStatus
            this.params.filter_store = this.filterArrStore
            this.params.filter_cashback_time = this.filterCashbackTimeSelected
            this.getMyTransactions(1, this.params);
        },
        resetData() {
            $(".btn-unclick1").css({ "background-color": "#E3EBF7", color: "#000" });
            $(".btn-unclick2").css({ "background-color": "#E3EBF7", color: "#000" });
            $(".btn-unclick3").css({ "background-color": "#E3EBF7", color: "#000" });
            this.filterArrCashbackEarnStatus = [];
            this.filterArrStore = [];
            this.filterCashbackTimeSelected = null;
            this.filterData()
        },

        format(date) {
            const getMonth = String(date.getMonth() + 1)
            const month = getMonth.padStart(2, '0')
            const year = String(date.getFullYear())
            return `${month}-${year}`
        },

        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
    },
    watch: {
        timeSelect(val) {
            if (val) {
                if (this.typeSelected == 1) {
                    this.getMyTransactions(this.typeSelected, this.params);
                } else {
                    this.getMyTransactions(this.typeSelected);
                }
            }
        }
    }
};
</script>
<style>
.transaction-tab {
    margin: 0px !important;
    padding: 0px;
    width: 100% !important;
}

.transaction-tab li {
    list-style: none;
    float: left;
    width: calc(100% / 2);
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.transaction-tab.show-point-transaction:not(.show-donation-transaction) li,
.transaction-tab.show-donation-transaction:not(.show-point-transaction) li
 {
    width: calc(100% / 3) !important;
}

.transaction-tab.show-point-transaction.show-donation-transaction li {
    width: calc(100% / 2) !important;
}

.transaction-tab li.active {
    border-bottom: 2px solid var(--label-color);
    color: var(--label-color);
}
.price-bold {
    font-weight: bold;
    font-size: 20px;
    padding-right: 10px;
}
.transaction-tab li {
    border-bottom: 2px solid rgb(238, 234, 234);
}

.my-transacions .accordion div button[aria-expanded=false] .collapse-close {
    display: block;
}

.my-transacions .accordion div button[aria-expanded=true] .collapse-close {
    display: none;
}

.text-custom {
    color: black !important;
    font-weight: 500;
}

.group-month {
    font-weight: 400;
}

.group-month {
    margin-right: 10px;
}
.pb-90 {
    padding-bottom: 90px !important;
}

.my-transacions [aria-expanded="true"] i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.brand-name {
    cursor: pointer
}

.text-status {
    color: #656565;
    font-weight: 600;
}

.transaction-item {
  padding: 5px;
  background: hsla(0, 0%, 100%, 0.5);
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.text-pedding {
    color: #25507A;
}

.text-confirmed {
    color: #30781C;
}

.cashback-detail > div {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
</style>