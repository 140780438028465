import api from "./api";

class BookingService {
  getBranches() {
    return api.get("/pos/branches");
  }

  getListVenue(dataForm) {
    return api.post("/member/booking/venues", dataForm);
  }

  venueDetail(dataForm) {
    return api.post("/member/booking/venue/detail", dataForm);
  }

  timeSlotDetail(dataForm) {
    return api.post("/member/booking/venue/timeslot/detail", dataForm);
  }

  getListTimeSlot(dataForm) {
    return api.post("/member/booking/venue/get-list-timeslot", dataForm);
  }

  checkoutSessionStripe(dataForm, merchant_code) {
    return api.post('/member/booking/checkout-session-stripe', dataForm, {
        headers: {
        'MerchantCode': merchant_code
        }
      }
    );
  }

  assignTimeSlotToMember(dataForm) {
    return api.post("/member/booking/venue/assign-timeslot-to-member", dataForm);
  }

  assignTimeSlotFreeToMember(dataForm) {
    return api.post("/member/booking/venue/assign-timeslot-free-to-member", dataForm);
  }

  memberBookingDetail(dataForm) {
    return api.post("/member/booking/detail", dataForm);
  }

  memberBookingHistory(dataForm) {
    return api.post("/member/booking/history", dataForm);
  }
}

export default new BookingService();
