import api from './api';

class MemberService {
  updateProfile(dataForm) {
    return api.post('/member/update-profile', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getProfile() {
    return api.get('/member/get-profile');
  }

  checkProfile() {
    return api.get('/member/check-profile');
  }

  getMemberInfo() {
    return api.get('/member/get-member-info');
  }

  getCountries() {
    return api.get('/countries');
  }

  deleteMerchant(merchantId) {
    return api.post('/admin/merchant/delete/' + merchantId);
  }

  getMyTransactions(type, timeSelect, paramsFilter) {
    let typeTransaction = { type: type, timeSelect: timeSelect }
    let params = {
      ...typeTransaction,
      ...paramsFilter
    };

    return api.post('/member/my-transactions', params);
  }

  transactionDetail(transaction_id) {
    return api.get('/member/transaction-detail/' + transaction_id);
  }

  sendMailContact(dataForm) {
    return api.post('/member/contact-us', dataForm);
  }

  getTopupList() {
    return api.get('/member/topup-list');
  }

  assignPaidMembershipRewardsToMember(dataForm) {
    return api.post('/member/assign-paid-membership-rewards-to-member', dataForm);
  }

  checkMemberPermissions() {
    return api.post('/member/check-member-permissions', {
      merchant_code: localStorage.getItem('MERCHANT_CODE', '')
    });
  }

  getMembershipFeeMinByTier(dataForm) {
    return api.post('/member/get-membership-fee-min-by-tier', dataForm);
  }

  getRewardPerTransactionSetting() {
    return api.get('/member/get-reward-per-transaction-setting');
  }

  assignPaidMembershipRewardsToMemberUsingCashbackTopup(dataForm) {
    return api.post('/member/assign-paid-membership-rewards-to-member-using-cashback-topup', dataForm);
  }

  getMerchantId(dataForm) {
    return api.post('/pos/get-merchant-id', dataForm);
  }

  getAdmissionsTransactions(type, timeSelect, paramsFilter) {
    let typeTransaction = { type: type, timeSelect: timeSelect }
    let params = {
      ...typeTransaction,
      ...paramsFilter
    };

    return api.post('/member/my-admissions-transactions', params);
  }

  getCountriesForDropdown() {
    return api.get('/countries-for-dropdown');
  }
}

export default new MemberService();
