<template>
  <div
    class="page-header align-items-start"
    :style="styleObject"
    v-if="!isConfirmPayment"
  >
    <span class="mask bg-white"></span>
    <div class="container p-0" v-if="isLoadDone">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body ps-0 pe-0 pb-90">
            <div
              @click="checkTermsConditions ? goToTimeSlot() : goToBooking()"
              class="pb-0 font-weight-bolder h4 px-2"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              <i class="fas fa-chevron-left me-2"></i> {{ venue.name }}
            </div>
            <div v-if="checkTermsConditions">
              <div class="mt-3 timeslot-detail">
                <div>{{ formatDate(timeSlotSelect.date) }}</div>
                <div class="mt-1">{{ timeSlotSelect.time }}</div>
              </div>
              <div class="mt-3 px-2">
                <div
                  class="color-common font-weight-bolder text-center"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  Terms & Conditions
                </div>
                <div class="font-content text-dark c-terms-conditions mt-3">
                  <img
                    :src="venue.image"
                    class="d-block w-100 mobile-slide"
                    alt="Error"
                  />
                  <div v-html="venue.terms_conditions" class="mt-3"></div>
                </div>
              </div>
            </div>
            <div v-else class="c-booking">
              <div
                class="font-weight-bolder h5 text-center mt-3"
                :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
              >
                Type
              </div>
              <div class="mt-3">
                <div
                  v-if="venue.types.length"
                  class="row justify-content-around mx-2"
                >
                  <div
                    class="col-6 mb-2 text-center"
                    v-for="(type, index) in venue.types"
                    :key="index"
                    @click="selectType(type)"
                  >
                    <div class="type-tag" :class="['type-tag-' + type.id]">
                      <div class="text-title">{{ type.name }}</div>
                      <div class="text-title">{{ type.price_format }}</div>
                    </div>
                  </div>
                  <div class="col-6"></div>
                </div>
                <div
                  v-else
                  class="d-flex justify-content-center p-5"
                  style="background-color: #f0f2f5"
                >
                  No types available
                </div>
              </div>
              <div v-if="typeSelect.id">
                <div
                  class="font-weight-bolder h5 text-center mt-3 mb-0"
                  :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                >
                  Date
                </div>
                <div>
                  <Datepicker
                    v-model="dateSelect"
                    :enableTimePicker="false"
                    class="date-time venue-date-time"
                    :format="format"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :inline="{ input: true }"
                    text-input
                    auto-apply
                  />
                </div>
                <div
                  v-if="timeSlot.length"
                  class="font-weight-bolder h5 text-center"
                  :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                >
                  Time
                </div>
                <div class="mt-3">
                  <div
                    v-if="timeSlot.length"
                    class="row justify-content-around mx-2"
                  >
                    <div
                      class="col-6 mb-2 text-center"
                      v-for="(item, index) in timeSlot"
                      :key="index"
                      @click="item.status ? selectTimeSlot(item) : null"
                    >
                      <div
                        class="timeslot-tag h-100"
                        :class="[
                          'timeslot-tag-' + item.id,
                          { 'timeslot-deactive': !item.status },
                          { 'd-flex justify-content-center align-items-center': item.status}
                        ]"
                      >
                        <div class="text-title">{{ item.time_text }}</div>
                        <div v-if="!item.status" class="font-weight-bold text-danger mt-1">Not Available</div>
                      </div>
                    </div>
                    <div class="col-6"></div>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-center p-5"
                    style="background-color: #f0f2f5"
                  >
                    No slots available
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-timeslot mx-auto">
        <button
          v-if="checkTermsConditions"
          @click="processBooking()"
          :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
          type="button"
          class="btn bg-color-common btn-success mx-auto"
        >
          Continue
        </button>
        <button
          v-else
          @click="bookThisVenue()"
          :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
          type="button"
          class="btn bg-color-common btn-success mx-auto"
          :disabled="!timeSlotSelect.id"
        >
          Next
        </button>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
  <div v-else>
    <ConfirmPayment
      :typeSelect="typeSelect"
      :timeSlotSelect="timeSlotSelect"
      @back-terms-conditions="backTermsConditions()"
    ></ConfirmPayment>
  </div>
</template>

<script>
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import BookingService from "../services/booking.service";
import Datepicker from "@vuepic/vue-datepicker";
import ConfirmPayment from "@/components/BookingConfirmPayment.vue";
import $ from "jquery";
import moment from "moment";

export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    Datepicker,
    ConfirmPayment,
  },
  data() {
    return {
      isLoadDone: false,
      merchantCode: "",
      loaderActive: false,
      snackbar: "",
      message: "",
      dateSelect: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      venueId: this.$route.params.id ?? 0,
      venue: {},
      branchIdSelect: this.$route.query.branch_id ?? 0,
      typeSelect: {},
      timeSlot: [],
      timeSlotSelect: {},
      checkTermsConditions: false,
      isConfirmPayment: false,
      interval: null,
    };
  },
  async created() {
    this.showLoader();
    await this.venueDetail();
    this.getTimeSlot();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";

      this.interval = setInterval(() => {
        if (!this.checkTermsConditions && this.typeSelect.id) {
          this.getTimeSlot();
        }
      }, 15000);
  },
  computed: {
    styleObject() {
      return {
        "--button-color": this.$store.state.buttonColor,
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    goToBooking() {
      this.$router.push(this.merchantCode + "/booking?branch_id=" + this.branchIdSelect);
    },

    backTermsConditions() {
      this.isConfirmPayment = false;
    },

    bookThisVenue() {
      this.checkTermsConditions = true;
    },

    goToTimeSlot() {
      this.checkTermsConditions = false;
      this.getTimeSlot();
      setTimeout(() => {
        this.selectType(this.typeSelect);
      }, 100);
    },

    formatDate(date) {
      return moment(date).format("dddd, D MMMM YYYY");
    },

    selectType(type) {
      this.typeSelect = type;
      $(".type-tag").removeClass("active");
      $(".type-tag-" + type.id).addClass("active");
    },

    selectTimeSlot(slot) {
      this.timeSlotSelect = {
        id: slot.id,
        time: slot.time_text,
        price: this.typeSelect.price,
        date: this.dateSelect,
      };
      $(".timeslot-tag").removeClass("active");
      $(".timeslot-tag-" + slot.id).addClass("active");
    },

    format(date) {
      const getDay = String(date.getDate());
      const day = getDay.padStart(2, "0");
      const getMonth = String(date.getMonth() + 1);
      const month = getMonth.padStart(2, "0");
      const year = String(date.getFullYear());
      return `${day}-${month}-${year}`;
    },

    async venueDetail() {
      this.isLoadDone = false;
      var dataForm = {
        venue_id: this.venueId,
        branch_id: this.branchIdSelect,
      };

      await BookingService.venueDetail(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.venue = response.data.data;
            this.maxDate.setTime(this.maxDate.getTime() + (this.venue.max_advance_time * 24 * 60 * 60 * 1000));
            this.isLoadDone = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          console.log(error);
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    getTimeSlot() {
      this.showLoader();
      var dataForm = {
        venue_id: this.venueId,
        date_select: this.dateSelect,
        branch_id: this.branchIdSelect,
      };

      BookingService.getListTimeSlot(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.timeSlot = response.data.data;
            $(".timeslot-tag").removeClass("active");
            this.timeSlotSelect = {};
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    processBooking() {
      if (this.timeSlotSelect.price == 0) {
        var dataForm = {
          venue_id: this.venueId,
          venue_type_id: this.typeSelect.id,
          slot_id: this.timeSlotSelect.id,
          date_select: this.dateSelect,
          branch_id: this.branchIdSelect,
        };

        BookingService.assignTimeSlotFreeToMember(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              let bookingCode = response.data.data;
              this.$router.push(
                this.merchantCode + "/booking-completed/" + bookingCode
              );
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      } else {
        this.isConfirmPayment = true;
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    dateSelect(val) {
      if (val) {
        this.getTimeSlot();
      }
    },
  },
};
</script>
<style scoped>
.page-header {
  height: calc(100vh - 56px);
}

.c-booking {
  height: calc(100vh - 175px);
  overflow-y: auto;
}

.timeslot-tag,
.type-tag {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 10px 5px;
}

.timeslot-tag.active,
.type-tag.active {
  background: #3aac5d;
}

.timeslot-tag.active > div,
.type-tag.active > div {
  color: #fff !important;
}

.footer-timeslot {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  left: 0px;
}

.timeslot-detail {
  text-align: center;
  color: #fff !important;
  background: #3aac5d;
  padding: 20px 0;
}

.c-terms-conditions {
  height: calc(100vh - 350px);
  overflow-y: auto;
}

.timeslot-deactive .text-title {
  color: #7b809a !important;
}
</style>
