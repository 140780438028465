<template>
    <div class="page-header align-items-start min-vh-screen" :style="getLevels">
        <span class="mask bg-white"></span>
        <div class="container" v-if="isShowLayout">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12 pt-2 d-flex">
                    <div class="col-lg-1 col-md-1 col-1 pt-2 custom-icon-back">
                        <a @click="myTransactions()"><i class="fas fa-chevron-left color-common"
                                :style="`color: ${this.$store.state.labelColor} !important`"></i></a>
                    </div>
                    <div class="col-lg-10 col-md-10 col-10 pt-2 color-common font-weight-bolder d-flex justify-content-center align-items-center"
                        :style="`color: ${this.$store.state.labelColor} !important`">
                        Loyalty Tier Benefit
                    </div>
                    <div class="col-lg-1 col-md-1 col-1 pt-2">
                    </div>
                </div>
                <div class="col-lg-12 col-md-8 col-12 mx-auto" v-if="tiers.length">
                    <div>
                        <div>
                            <div class="row my-3">
                                <ul class="progressbar">
                                    <div class="d-flex justify-content-center">
                                        <li class="text-size-14" style="position: relative;" v-for="(tier, index) in tiers" :key="index" v-bind:class="tier.is_current_tier ? 'active' : ''" :id="`tier_${tier.id}`" @click="tierDefault(tier)">
                                            <div style="position: absolute; width: 150px; left: calc(50% - 75px)">{{ tierDefaultDisplay.id == tier.id ? tier.tier_name : '' }}</div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            <div class="row rounded-3 border shadow p-3 bg-body mt-5">
                                <div class="col-lg-9 col-9 mx-auto">
                                    <h6 style="text-transform: uppercase">{{ tierDefaultDisplay.tier_name }} Tier</h6>
                                    <div class="text-size-14" v-if="tierDefaultDisplay.show_tier_condition">{{ tierDefaultDisplay.note_text }}</div>
                                </div>
                                <div class="col-lg-3 col-3 mx-auto d-flex justify-content-right align-items-center" style="position: relative;">
                                    <img :src="tierDefaultDisplay.tier_badge_url" class="avatar avatar-md border-radius-lg"
                                        alt="user1" />
                                    <i v-if="tierDefaultDisplay.is_current_tier" class="fa fa-check-circle color-common" aria-hidden="true" style="position: absolute; right: 13px; bottom: 11px; color:#3aac5d"></i>
                                </div>
                            </div>
                            <div v-if="tierDefaultDisplay.show_tier_description">
                                <div class="row my-3" v-if="tierDefaultDisplay.description">
                                    <div class="color-common font-weight-bolder"
                                        :style="`color: ${this.$store.state.labelColor} !important`">
                                        Description
                                    </div>
                                </div>
                                <div class="row mx-2 text-size-14" v-if="tierDefaultDisplay.description">
                                    {{ tierDefaultDisplay.description }}
                                </div>
                            </div>
                            <div v-if="tierDefaultDisplay.show_tier_condition">
                                <div class="row my-3" v-if="tierDefaultDisplay.spending_achieve_tier_format || tierDefaultDisplay.duration_achieve_membership">
                                    <div class="color-common font-weight-bolder"
                                        :style="`color: ${this.$store.state.labelColor} !important`">
                                        Condition
                                    </div>
                                </div>
                                <div class="row mx-2 text-size-14">
                                    {{ tierDefaultDisplay.is_paid_member_ship ? "Paid Membership" : "Free Membership" }}
                                </div>
                                <div class="row mx-2 text-size-14" v-if="tierDefaultDisplay.is_paid_member_ship && tierDefaultDisplay.already_achieved_a_paid_tier_previously">
                                    Already achieved a paid tier previously
                                </div>
                                <div class="mx-2 text-size-14 d-flex align-items-center" v-if="tierDefaultDisplay.is_paid_member_ship">
                                    Membership Fee {{ tierDefaultDisplay.membership_fee_total_format }}
                                    <div class="btn-complted ms-2" v-if="tierDefaultDisplay.completed_payment_membership_fee">
                                        Completed
                                    </div>
                                </div>
                                <div class="row mx-2 text-size-14" v-if="tierDefaultDisplay.spending_achieve_tier > 0 && tierDefaultDisplay.spending_achieve_tier_format">
                                    <div class="px-0" v-if="tierDefaultDisplay.upgrade_tier_by_admissions">
                                        Visit {{ tierDefaultDisplay.visit_achieve_tier_format }} times
                                    </div>
                                    <div class="px-0" v-else>
                                        Spend {{ tierDefaultDisplay.spending_achieve_tier_format }}
                                    </div>
                                </div>
                                <div class="row mx-2 text-size-14" v-if="tierDefaultDisplay.duration_achieve_membership">
                                    Remain loyalty for {{ tierDefaultDisplay.duration_achieve_membership }} {{ tierDefaultDisplay.duration_achieve_membership_unit_text ? tierDefaultDisplay.duration_achieve_membership_unit_text.label : '' }}
                                </div>
                            </div>
                            <div class="row my-3" v-if="tierDefaultDisplay.standard_rule_text || tierDefaultDisplay.superior_rule_text || tierDefaultDisplay.welcome_voucher_text || tierDefaultDisplay.birthday_voucher_text">
                                <div class="color-common font-weight-bolder"
                                    :style="`color: ${this.$store.state.labelColor} !important`">
                                    Benefit
                                </div>
                            </div>
                            <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.standard_rule_text">
                                <i class="fas fa-check pe-2 pt-1"></i>{{ tierDefaultDisplay.standard_rule_text }}
                            </div>
                            <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.superior_rule_text">
                                <i class="fas fa-check pe-2 pt-1"></i>{{ tierDefaultDisplay.superior_rule_text }}
                            </div>
                            <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.welcome_voucher_text" style="">
                                <i class="fas fa-check pe-2 pt-1"></i>Welcome Benefit
                                <p style="margin-bottom: 0px;" class="text-size-14" v-for="(line, index) in tierDefaultDisplay.welcome_voucher_text.split('---')" :key="index">{{ line ? " - "+line : '' }}</p>
                            </div>
                             <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.welcome_direct_cashback_text">
                                <i class="fas fa-check pe-2 pt-1"></i>{{ tierDefaultDisplay.welcome_direct_cashback_text }}
                            </div>
                            <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.birthday_voucher_text" style="">
                                <i class="fas fa-check pe-2 pt-1"></i>Birthday Benefit
                                <p style="margin-bottom: 0px;" class="text-size-14" v-for="(line, index) in tierDefaultDisplay.birthday_voucher_text.split('---')" :key="index">{{ line ? " - "+line : '' }}</p>
                            </div>
                            <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.birthday_direct_cashback_text && nodeEnv === 'staging' && (tierDefaultDisplay.standard_rule_text || tierDefaultDisplay.superior_rule_text)">
                                <i class="fas fa-check pe-2 pt-1"></i>{{ tierDefaultDisplay.birthday_direct_cashback_text }}
                            </div>
                            <div class="mx-2 text-size-14" v-if="tierDefaultDisplay.birthday_extra_rebase_text && nodeEnv === 'staging' && (tierDefaultDisplay.standard_rule_text || tierDefaultDisplay.superior_rule_text)">
                                <i class="fas fa-check pe-2 pt-1"></i>{{ tierDefaultDisplay.birthday_extra_rebase_text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-8 col-12 mx-auto d-flex justify-content-center" v-else>
                    No tier yet!
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
        <material-loading :active="loaderActive" />
    </div>

</template>

<script>
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import TierService from "../services/tier.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import $ from "jquery"

export default {
    name: "sign-in",
    components: {
        MaterialSnackbar,
        MaterialLoading,
    },
    data() {
        return {
            transactionDetail: {},
            transactionType: [],
            snackbar: null,
            loaderActive: false,
            tiers: [],
            tierDefaultDisplay: {},
            hasCurrentTier: false,
            isShowLayout: false,
            nodeEnv: process.env.VUE_APP_NODE_ENV,
        };
    },
    created() {
        this.getTiers()
    },
    computed: {
        getLevels() {
            return {
                '--levels': this.tiers.length,
            };
        },
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
        getTiers() {
            this.isShowLayout = false;
            this.showLoader()
            TierService.getListTier().then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.tiers = response.data.data
                        if(this.tiers.length) {
                            this.tiers.forEach((tier) => {
                                if(tier.is_current_tier) {
                                    this.hasCurrentTier = true
                                    this.tierDefault(tier)
                                }
                            });
                            if(!this.hasCurrentTier) {
                                this.tierDefault(this.tiers[0])
                                this.setActiveDefault(this.tiers[0])
                            }
                        }
                        this.isShowLayout = true
                    } else {
                        this.snackbar = 'danger'
                        this.message = response.data.result.message
                        this.setTimeOutNotification()
                    }
                    this.hideLoader()
                },
                (error) => {
                    this.isShowLayout = false;
                    this.snackbar = 'danger'
                    this.message = error
                    this.setTimeOutNotification()
                    this.hideLoader()
                }
            );
        },

        tierDefault(tier) {
            $('li').removeClass('active')
            $('#tier_' + tier.id).addClass('active')
            this.tierDefaultDisplay = tier
        },

        myTransactions() {
            this.$router.go(-1)
        },

        setActiveDefault(tier) {
            setTimeout(() => {
                $('#tier_' + tier.id).addClass('active')
            }, 50);
        },

        setTimeOutNotification() {
            setTimeout(() => { this.snackbar = null; }, 3000);
        },

        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
    },
};
</script>
<style>
.custom-icon-back {
    font-size: 25px;
}

.progressbar {
  counter-reset: step;
  padding: 0;
  /* for demo */
  margin: 100px auto 0;
  max-width: 800px;
}

.progressbar li {
  float: left;
  list-style: none;
  position: relative;
  text-align: center;
  width: calc(100% / var(--levels));
}

.progressbar li:before {
  background: #fff;
  border: 2px solid #bebebe;
  border-radius: 50%;
  color: #bebebe;
  content: counter(step);
  counter-increment: step;
  display: block;
  font-weight: 700;
  height: 30px;
  line-height: 27px;
  margin: 0 auto 10px;
  text-align: center;
  width: 30px;
  cursor: pointer;
}

.progressbar li:after {
  background: #979797;
  content: '';
  height: 3px;
  left: -50%;
  position: absolute;
  top: 15px;
  width: 100%;
  z-index: -1;
  cursor: pointer;
}

.progressbar li:first-child:after {
  content: none;
}

/* .progressbar li.active:after, */
/* .progressbar li.complete:after {
  background: #3aac5d;
} */

.progressbar li.active:before,
.progressbar li.complete:before {
  background: #3aac5d;
  border-color: #3aac5d;
  color: #fff;
}

.progressbar li.active {
  color: #3aac5d;
  font-weight: 700;
}

.btn-complted {
    padding: 3px 10px;
    background: #3aac5d;
    border-color: #3aac5d;
    border-radius: 10px;
    color: #fff;
}

.progressbar > div > li:last-child > div,
.progressbar > div > li:first-child > div {
    width: 90px !important;
    left: calc(50% - 45px) !important;
}
</style>