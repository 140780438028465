import api from './api';

class HomeService {
  getDataHomePage() {
    return api.get('/member/home');
  }
  
  getRewardDetail(reward_id) {
    return api.get('/member/rewards/detail/' + reward_id);
  }

  processExchange(reward_id) {
    return api.post('/member/rewards/exchange', {reward_id: reward_id});
  }

  processExchangeByCard(reward_id, session_id) {
    return api.post('/member/rewards/exchange-by-card', {reward_id: reward_id, session_id: session_id});
  }

  getDonationTermsConditions() {
    return api.get('/member/donation-terms-conditions');
  }
}

export default new HomeService();
